// .mainContainer {
// min-height: 220px;

// background-color: white;
// border-radius: 8px;
// width: 100%;
// padding: 0px 10px 15px 10px;
// box-shadow: rgba(0, 0, 0, 0.35) 0px 2.5px 5px;
// box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
// box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
// box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
.DetailDashboard {
    cursor: pointer;
    margin: 0px;
    padding: 10px;
    background: white;
    border-radius: 10px;
    box-shadow: 3px 1px 9px -5px rgba(0, 0, 0, 0.3);
}
.DetailDashboard:hover {
    background: lightgray;
}
.detailDashmainCard {
    // padding: 10px;
    background: white;
    // border-radius: 10px;
    border-right: 1px solid rgba(0, 0, 0, 0.06);
    // box-shadow: 3px 1px 9px -5px rgba(0, 0, 0, 0.3);
    cursor: pointer;
}
.detailDashmainCardsubMenu {
    padding: 10px !important;
    background: white;
    // border-radius: 10px;
    border-right: 1px solid rgba(0, 0, 0, 0.06);
    // box-shadow: 3px 1px 9px -5px rgba(0, 0, 0, 0.3);
    cursor: pointer;
}
.detailDashmainCardGraphsubMenu {
    padding: 10px !important;
    background: white;
    // border-radius: 10px;
    border-right: 1px solid rgba(0, 0, 0, 0.06);
    // box-shadow: 3px 1px 9px -5px rgba(0, 0, 0, 0.3);
    cursor: pointer;
}
.detailDashmainCardsubMenu:hover {
    // padding: 10px !important;
    background: lightgrey;
    // border-radius: 10px;
    border-right: 1px solid rgba(0, 0, 0, 0.06);
    // box-shadow: 3px 1px 9px -5px rgba(0, 0, 0, 0.3);
    cursor: pointer;
}
.detailDashAfterSalemainCard {
    // padding: 10px;

    border-right: 1px solid rgba(0, 0, 0, 0.06);

    cursor: pointer;
}
.detailDashmainCard:hover {
    // padding: 10px;
    background: lightgray;
    // border-radius: 5px;
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    // box-shadow: 3px 1px 9px -5px rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

.detailDashmainCard1 {
    // padding: 10px;
    background: white;
    border-radius: 5px !important;
    box-shadow: 3px 1px 9px -5px rgba(0, 0, 0, 0.3);
    // cursor: pointer;
}

.grpDetailKpiBox {
    text-align: center;
    font-size: 18px !important;
    font-weight: 600 !important;

    // background-color: #f5f5f5;
}
.lableFont {
    font-size: 12px;
}

.mainText {
    font-size: 20px !important;
    font-weight: 700 !important;
    // background-color: #f5f5f5;
    // padding-top: 10px;
}
.secondaryHeading {
    font-size: 14px !important;
    color: #2195b0;
    font-weight: 500 !important;
}

.BOringProperty {
    height: 80px;
    width: 80px;
}

.ml-10 {
    margin-left: 5px !important;
}

.CircularProgressbar .CircularProgressbar-text {
    text-anchor: middle;
    font-size: 22px !important;
    // alignment-baseline: middle;
    dominant-baseline: central !important;
}
.fontFamilyMontserratClass {
    font-family: Montserrat !important;
    // }
}

.vehicleRing {
    height: 80px;
    width: 80px;
}

.primaryColor {
    color: #2195b0;
}

.salesCardTextPrimary {
    text-align: center;
    font-size: 12px !important;
    font-weight: 600 !important;
    text-transform: uppercase;

    // background-color: #f5f5f5;
}
.mainCardTextPrimary {
    text-align: center;
    font-size: 14px !important;
    font-weight: 600 !important;

    // background-color: #f5f5f5;
}

.salesCurrencyFont {
    font-size: 14px !important;
    font-weight: 700 !important;
}

.MainHeading {
    font-size: 20px !important;
    font-weight: 700 !important;
    text-align: left;
    color: #f50057 !important;
}

.salesCurrencyFont {
    padding: 0px;
}

.p-10 {
    padding: 3.5px;
}
.p-100 {
    padding: 10px;
}

// @media screen and (max-width: 1099px) {

// }

@media screen and (max-width: 1280px) {
    .detailDashmainCardsubMenu {
        // padding: 20px !important;
        margin: 5px 0px !important;
        background: white;
        // border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.06);
        // box-shadow: 3px 1px 9px -5px rgba(0, 0, 0, 0.3);
        cursor: pointer;
    }
    .vehicleRing {
        height: 63px;
        width: 73px;
    }

    .p-10 {
        padding: 5px;
    }

    .mainCardTextPrimary {
        font-size: 12px !important;
        font-weight: 600 !important;
    }

    .detailDashmainCard {
        border-bottom: 1px solid rgba(0, 0, 0, 0.09);
    }

    .salesCurrencyFont {
        font-size: 15px !important;
        font-weight: 700 !important;
    }

    .salesCardTextPrimary {
        font-size: 9px !important;
        font-weight: 600 !important;
    }
}

@media screen and (max-width: 1024px) {
    .detailDashmainCardsubMenu {
        // padding: 20px !important;
        margin: 5px 0px !important;
        background: white;
        // border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.06);
        // box-shadow: 3px 1px 9px -5px rgba(0, 0, 0, 0.3);
        cursor: pointer;
    }
    .mainCardTextPrimary {
        font-size: 11px !important;
    }

    .p-10 {
        padding: 10px;
    }

    .ml-10 {
        margin-left: 0px !important;
    }

    .salesCurrencyFont {
        font-size: 12px !important;
        // font-weight: 700 !important;
    }

    .mainText {
        font-size: 13px !important;
    }

    .vehicleRing {
        height: 65px;
        width: 65px;
    }

    .salesCardTextPrimary {
        // text-align: center;
        font-size: 9px !important;
        // font-weight: 600 !important;
    }

    .grpDetailKpiBox {
        text-align: center;
        font-size: 14px !important;
    }
    .MainHeading {
        font-size: 15px !important;
        text-align: left;
    }
}
@media screen and (max-width: 480px) {
    .DetailDashboard {
        cursor: pointer;
        margin: 0px !important;
        min-width: 100px;

        padding: 10px !important;
        background: white;
        border-radius: 10px;

        box-shadow: 3px 1px 9px -5px rgba(0, 0, 0, 0.3);
    }

    .detailDashmainCardsubMenu {
        // padding: 20px !important;
        margin: 5px 0px !important;
        background: white;
        // border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.06);
        // box-shadow: 3px 1px 9px -5px rgba(0, 0, 0, 0.3);
        cursor: pointer;
    }
}

// .mainContainer {
//     min-height: 200px;
//     background-color: white;
//     border-radius: 8px;
//     width: 100%;
//     padding: 10px;
//     // box-shadow: rgba(0, 0, 0, 0.35) 0px 2.5px 5px;
//     // box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
//     // box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
//     box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
//     .grpDetailKpiBox {
//         border-radius: 4px;
//         text-align: left;
//         font-size: 24px;
//         font-weight: 700;
//     }
//     .CircularProgressbar .CircularProgressbar-text {
//         text-anchor: middle;
//         // alignment-baseline: middle;
//         dominant-baseline: central !important;
//     }
//     .fontFamilyMontserratClass {
//         font-family: Montserrat !important;
//     }
// }
